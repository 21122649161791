import React from "react";
import { Link } from "react-router-dom";
import loginBanner from "../assets/imagesNew/login-banner1.webp";
import homeIcon from "../assets/imagesNew/home.png";
import discoverIcon from "../assets/imagesNew/research.png";
import supportIcon from "../assets/imagesNew/support.png";
const MenuListing = ({ sidebar, handleOpen }) => {
  return (
    <>
      {sidebar && <div className="overlay" onClick={() => handleOpen()}></div>}
      <div className={sidebar ? "menulist-sidebar active" : "menulist-sidebar"}>
        <div className="menu-header">
          <h2>bKash Menu</h2>
          <Link to="">English</Link>
        </div>

        <div>
          <ul>
            <li>
              <Link to="/">
                <div>
                  <img src={homeIcon} alt="" />
                </div>
                Home
              </Link>
            </li>
            <li>
              <Link to="/">
                <div>
                  <img src={discoverIcon} alt="" />
                </div>
                Discover bKash
              </Link>
            </li>
            <li>
              <Link to="/">
                <div>
                  <img src={supportIcon} alt="" />
                </div>
                Support
              </Link>
            </li>
          </ul>
        </div>

        <div className="banner-menu-listing">
          <img src={loginBanner} alt="" className="w-100" />
        </div>
        <div className="menu-listing-bottom">
          <p>
            From <Link to="/">bKash Menu</Link> You can easily track your
            transaction limits, see your account statements, and many more.
          </p>
          <div className="login-reg-btn text-center">
            <Link to="/" className="active text-center">
              Login / Registration
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuListing;
