import React, { useContext,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { DepositWithrawData, DepositWithrawDataNew } from "../Utils/constants";
import HomeIcon from "../assets/images/toolbar-icon-home.svg";
import PromotionsIcon from "../assets/images/toolbar-icon-promotion.svg";
import DepositIcon from "../assets/images/toolbar-icon-deposit.svg";
import AccountIcon from "../assets/images/toolbar-icon-mine.svg";
import Registration from "../Auth2/Registration";

const FooterNew = () => {
  const { user, lang, setEn, setBr  } = useContext(AuthContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="footer-new">
      <ul>
        
        {isEmpty(user) ? (
          <>
          <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
              if (lang == "bn") {
                setEn();
              } else {
                setBr();
              }
            }}
          >
            <img src={lang == "bn" ? FlagIcon : FlagIconBn} alt="" />{" "}
            {/* {lang == "bn" ? "INR" : "BDT"} <br />{" "} */}
            {lang == "bn" ? "English" : "Bangla"}
          </div>
        </li>
        <li className="register-button">
              <Link to="/register">{t("Sign_up")}</Link>
            </li>
            <li className="login-button">
              <Link to="/login">{t("Login")}</Link>
            </li>
          </>
        ) : (
          <>
            <li onClick={() => navigate("/")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={HomeIcon} />
                </figure>
                <span>{t("Home")}</span>
              </div>
            </li>
            
            <li onClick={() => navigate("/deposit")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={DepositIcon} alt="" />
                </figure>
                <span>
                  {lang == "bn"
                    ? DepositWithrawDataNew[0]?.titlebn
                    : DepositWithrawDataNew[0]?.title}
                </span>
              </div>
            </li>
            <li onClick={() => navigate("/withdraw")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={PromotionsIcon} alt="" />
                </figure>
                <span>
                  {lang == "bn"
                    ? DepositWithrawDataNew[1]?.titlebn
                    : DepositWithrawDataNew[1]?.title}
                </span>
              </div>
            </li>
            <li onClick={() => navigate("/myaccount")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={AccountIcon} alt="" />
                </figure>
                <span>{t("My Account")}</span>
                {/* <span>
                  {lang == "bn"
                    ? DepositWithrawDataNew[1]?.titlebn
                    : DepositWithrawDataNew[1]?.title}
                </span> */}
              </div>
            </li>
          </>
        )}
      </ul>
      
    </div>
  );
};

export default FooterNew;
