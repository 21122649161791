import React, { useState } from "react";
import { useContext } from "react";
import { useNavigate,useParams,navigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";


const CasinoIframe = () => {
    let {setCasinoName,casinoName} = useContext(AuthProvider);
    const parmas = useParams();
    const navigate = useNavigate();
   const cas= localStorage.getItem("casinoUrl");
   console.log(localStorage.getItem("casinoUrl"));
    return (
    
      cas && (
            <div id="casinopopup" class="casino-iframe-overlay" >
              {/* <div className="casinpheader"><span>{casinoName}</span> <a id="closepopsaba" class="close-casino-iframe" onClick={() => {navigate("/");setCasinoName("");localStorage.getItem("casinoUrl","")}}>X</a> </div>  */}
            <div id="commonDialogWrap" class="pop-wrap transfer-wrap-slider" style={{"background":`none`,'width': `100%`,'height': `881px`}}>
            <iframe preload="" allowfullscreen="" title="mob-live-stream" src={cas}  name="someFrame" scrolling="auto" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups" />
            </div>
            </div>
          )
    );
};

export default CasinoIframe;
