import React, { useContext } from "react";
import { Link, useNavigation } from "react-router-dom";
import { JioBetSpecialData } from "../../Utils/constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";

const JioBetSpecial = () => {
  
  const { t } = useTranslation();
  const { lang, user, launchEGTCasino, launchCasino,withoutLogin,dolaunchCasinoNew } = useContext(AuthContext);
  const navigate = useNavigation();
  return (
    <>
      <div className="common-slider">
        <div className="heading-block">
          <h5>{t("Jiobet Special")}</h5>
          {/* <Link to="/">{t("See_All")}</Link> */}
        </div>

        <div className="">
          <ul className="slot-data-list">
          {JioBetSpecialData.map((item) => {
              return (
            <li
            onClick={() => {
              if (!isEmpty(user)) {
                if (item?.gameTypeCheck == "platForm") {
                  launchCasino({
                    platForm: item?.platForm,
                    gameType: item?.gameType,
                    casinoType: item?.casinoType,
                  });
                }  else {
                 
                  dolaunchCasinoNew(item);
                  
                }
              } else {
                withoutLogin()
              }
          }} > <img src={item?.icons} alt="" style={{width: `100%`}} /></li>
            
            
          );
        })}           
          </ul>
          
            {/* {SlotData.map((item) => {
              return (
                <div
                  onClick={() => {
                      if (!isEmpty(user)) {
                        if (item?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: item?.platForm,
                            gameType: item?.gameType,
                            casinoType: item?.casinoType,
                          });
                        }  else {
                         
                          dolaunchCasinoNew(item);
                          
                        }
                      } else {
                        withoutLogin()
                      }
                  }}
                  className="slider-items"
                >
                  <figure className="mb-0">
                    <img src={item?.icons} alt="" style={{width: `100%`}} />
                  </figure>
                  
                </div>
              );
            })} */}
          
        </div>
      </div>
    </>
  );
};

export default JioBetSpecial;
