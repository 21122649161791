import React, { useContext,useState ,useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import { isEmpty,toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import PromotionsIcon from "../assets/images/icon-promotion.webp";
import iconAffiliate from "../assets/images/icon-affiliate.png";
import HomeIcon from "../assets/images/icon-home.webp";
import iconTalk from "../assets/images/icon-member-talk.svg";
import iconTelegram from "../assets/images/icon-telegram.svg";
import iconAbous from "../assets/images/icon-abous-us.svg";
import iconFaq from "../assets/images/icon-faq.svg";

const MenuSidebarNew = () => {

  let { logoutUser, setShowSidebar,lang ,user, setEn, setBr,launchEGTCasino,
    launchCasino,withoutLogin} = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener('scroll', () => {
      
      if(window.scrollY > 180){
       
        SetActiveClass('gamefixed');
      }else{
        SetActiveClass('');
      }
      
   });

  }, []);
  const {t} = useTranslation()
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <div>
        <div className="sidebar-wrapper">
          {/* <div className="top-sidebar mb-3 ps-1 d-flex justify-content-between align-items-center">
           
            <button
              className="bg-transparent border-0 p-0"
              onClick={() => setShowSidebar(false)}
            >
              <RxCross2 />
            </button>
          </div> */}
          <ul>
          <li><figure> <img src={HomeIcon} alt=""/></figure><Link to="/" className="text-decoration-none"> <span>{"Home"}</span></Link></li>
          {/* <li><figure><img src={PromotionsIcon}alt=""/></figure><Link to="/promotions" className="text-decoration-none"> <span> Promotions </span></Link></li> */}
          </ul>
          <ul>
            <div className="title">Games</div>
        {DepositWithrawData?.map((item,index) => {
          return (
            <li key={index}  onClick={() => {setFunc(item?.type); }}className={`${key == item?.type ? "active" : ""}`}>
              <figure><img src={item?.icons} alt=""/></figure>
              <a className="text-decoration-none"> <span>{lang == "bn" ? item?.titlebn : item?.title}</span></a>
          </li>
          );
        })}
        </ul>
        <div className="title">Others</div>
        <ul>
          <li> <figure><img src={iconTalk} alt=""/></figure><Link to="#" className="text-decoration-none">   24/7 Live Chat  </Link></li>
          <li> <figure><img src={iconTelegram} alt=""/></figure><Link to="#" className="text-decoration-none"> Telegram Support  </Link></li>
          <li> <figure><img src={iconAffiliate} alt=""/></figure><Link to="#" className="text-decoration-none">   Affiliate  </Link></li>
          <li> <figure><img src={iconAbous} alt=""/></figure><Link to="#" className="text-decoration-none">   About Us  </Link></li>
          <li> <figure><img src={iconFaq} alt=""/></figure><Link to="#" className="text-decoration-none">   FAQ  </Link></li>

        </ul>
       
        <ul>
        
        {!isEmpty(user) ? (
          <>
          <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
             
              setBr();
              
            }}
          >
            <img src={FlagIconBn} alt="" />{" "}
            <p>বাংলা</p> 
          </div>
          <div
            className="language-select-div"
            onClick={() => {
              
                setEn();
              
            }}
          >
            <img src={ FlagIcon } alt="" />{" "}
            <p>English</p>
          </div>
        </li>
            
          </>):("")}</ul>
         
           
        </div>
        {
          key!="" &&
          <div class="menu-second">
          <ul class="menu-second-ul active">
          {data?.array?.map((res) => {
              return (
                  <li class=""   
                  onClick={() => {
                    if (!isEmpty(user)) {
                      if (res?.gameTypeCheck == "sports") {
                        window.open(res?.link, "_self");
                        } 
                     else if (data?.key == "sports") {
                      
                        
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                            });
                          
                      } 
                      else if (res?.type == "evo") {
                  
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                              isLobbyTrue:res?.isLobbyTrue
                            });
                          
                      } 
                      else if(data?.key == "hotgame"){
                        launchCasino({
                          platForm: res?.platForm,
                          gameType: res?.gameType,
                          casinoType: res?.casinoType,
                        });
                      } else{
                        if (!isEmpty(user)) {
                          let subCategory=res?.platForm=="EVOLUTION"?"EVO":res?.platForm=="SEXYBCRT"?"SEXY":res?.platForm;
                          navigate("/casino?header="+toLower(res?.key)+"&subCasino="+subCategory)
                          
                        } else {
                          navigate("/login");
                        }
                      }
                    } else {
                     navigate("/login");
                    }
                  }}>
                  <figure><img src={res?.image} alt=""/></figure><p>{res?.title}</p>
                  </li>
              );
            })}
        </ul>
      </div>
        }
       
      </div>
      
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
